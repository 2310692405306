import { I18n } from "i18n-js";

import en from "../../../config/locales/foodnerd.en.yml";
import ar from "../../../config/locales/translation.ar.yml";
import ur from "../../../config/locales/translation.ur.yml";

const i18n = new I18n();
i18n.enableFallback = true;
var loadedLocales = [];

function setI18nLocale(lcle) {
  if (!loadedLocales.includes(lcle)) {
    loadLocaleAsync(lcle).then(() => {
      i18n.locale = lcle;
    });
  } else {
    i18n.locale = lcle;
  }
}

export function loadLocaleAsync(lcle) {
  if (!loadedLocales.includes(lcle)) {
    return import(
      /* webpackChunkName: "lcle-[request]" */
      `./../i18n/${ lcle }.json`).then((translations) => {
        i18n.store(translations.default);
        loadedLocales.push(lcle);
        return lcle;
      });
  } else {
    return Promise.resolve(lcle);
  }
}

export function initLocale() {
  var container = typeof window !== 'undefined' ? window : global;
  var locale;
  if (!!container.i18n) {
    i18n.defaultLocale = container.i18n.defaultLocale;
    locale = container.i18n.locale;
  } else if (typeof window !== 'undefined') {
    locale = document.querySelector('meta[name=locale]').getAttribute('content');
  } else {
    locale = 'en';
  }
  if (locale) {
    if (locale == 'en' || locale == 'en-PK') {
      i18n.store(en);
    } else if (locale == 'ar') {
      i18n.store(ar);
    } else if (locale == 'ur') {
      i18n.store(ur);
    }
    setI18nLocale(locale);
  }

  i18n.setI18nLocale = setI18nLocale;
  container.i18n = i18n;
}

export { i18n as I18nRails };
